import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import {
  EventType,
  FeedType,
  getEventSubscription,
  NotificationType,
} from '@obeta/utils/lib/pubSub'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import { getCollectionSync } from '@obeta/app-bootstrap'
import { NOTIFICATION_HOST, NOTIFICATION_PATH } from '@obeta/utils/lib/config'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useLocation } from '@obeta/data/lib/hooks/useHistoryApi'

interface Props {
  userId?: string
  companyId?: string
}
enum OfferEvent {
  Add = 'ADD',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

enum OrderEvent {
  Update = 'UPDATE',
  Delete = 'DELETE',
}

enum OrderMetaDataEvent {
  Update = 'UPDATE',
}

export const SocketState = {
  Disconnected: 'Disconnected',
  Connected: 'Connected',
  ConnectionError: 'ConnectionError',
} as const
export type SocketState = typeof SocketState[keyof typeof SocketState]

export const SocketContext = createContext<SocketState>(SocketState.Disconnected)

export const Sockets: React.FC<PropsWithChildren<Props>> = (props) => {
  const { userId, companyId, children } = props

  const [socketState, setSocketState] = useState<SocketState>(SocketState.Disconnected)
  const location = useLocation()

  useEffect(() => {
    if (!companyId || !userId) {
      return
    }

    const query = {
      userId: `${companyId}-${userId}`,
    }
    const socket = io(`${NOTIFICATION_HOST}`, {
      transports: ['websocket'],
      path: `${NOTIFICATION_PATH}`,
      query,
    })
    socket.on('connect', () => {
      setSocketState(SocketState.Connected)
    })
    socket.on('connect_error', (error) => {
      console.error(error)
      setSocketState(SocketState.ConnectionError)
    })
    socket.on('disconnect', () => {
      if (socketState !== SocketState.ConnectionError) {
        setSocketState(SocketState.Disconnected)
      }
    })

    socket.on('order-channel', function (message) {
      if (message.event === 'OrderCreated') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.OrderSuccess,
          id: `${message.cartId}-${NotificationType.OrderSuccess}`,
          options: {
            cartId: message.cartId,
            shopPromotionEmail: message.shopPromotionEmail,
            shopPromotionId: message.shopPromotionId,
            date: message.date,
            offerId: message.offerId,
            orderId: message.orderId,
            pickupNumber: message.pickupNumber,
            shippingType: message.shippingType,
            storeId: message.storeId,
            time: message.time,
          },
        })
        return
      }
      if (message.event === 'OrderFailed') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.OrderFailure,
          id: `${message.cartId}-${NotificationType.OrderFailure}`,
          options: {
            cartId: message.cartId,
            reason: message.reason ?? OrderFailureReason.OrderSubmitFailure,
          },
        })
        return
      }
      if (message.event === 'OrderIdMissingInResponse') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.OrderIdMissingInResponse,
          id: `${message.cartId}-${NotificationType.OrderIdMissingInResponse}`,
          options: {
            cartId: message.cartId,
            reason: message.reason,
          },
        })
        return
      }
      if (message.event === 'SapInvoicePdfCreated' || message.event === 'SapInvoicesZipCreated') {
        const timestamp = new Date().getTime()

        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.SapInvoiceCreated,
          // Note: Id needs to be unique (in case invoice creation was triggered multiple times),
          // otherwise the notifications cannot be closed by the user
          id: `${message.userId}-${NotificationType.SapInvoiceCreated}-${timestamp}`,
          options: {
            fileUrl: message.fileUrl,
            invoiceIds: message.invoiceIds,
            missingInvoiceIds: message.missingInvoiceIds,
          },
        })
        return
      }
      if (message.event === 'SapInvoicePdfFailed' || message.event === 'SapInvoicesZipFailed') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.SapInvoiceFailed,
          id: `${message.userId}-${NotificationType.SapInvoiceFailed}`,
          options: {
            reason: message.reason,
          },
        })
        return
      }
      if (message.event === 'SapDeliveryNotePdfCreated') {
        const timestamp = new Date().getTime()

        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.DeliverySlipPDFCreated,
          // Note: Id needs to be unique (in case invoice creation was triggered multiple times),
          // otherwise the notifications cannot be closed by the user
          id: `${message.userId}-${NotificationType.DeliverySlipPDFCreated}-${timestamp}`,
          options: {
            fileUrl: message.fileUrl,
            // deliverySlipId: message.deliverySlipNote,
          },
        })
        return
      }
      if (message.event === 'SapDeliveryNotePdfFailed') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.DeliverySlipPDFFailed,
          id: `${message.userId}-${NotificationType.DeliverySlipPDFFailed}`,
          options: {
            reason: message.reason,
          },
        })
        return
      }
      if (message.event === 'SapOrderConfirmationPdfCreated') {
        const timestamp = new Date().getTime()

        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.OrderConfirmationPDFCreated,
          // Note: Id needs to be unique (in case invoice creation was triggered multiple times),
          // otherwise the notifications cannot be closed by the user
          id: `${message.userId}-${NotificationType.OrderConfirmationPDFCreated}-${timestamp}`,
          options: {
            fileUrl: message.fileUrl,
            orderId: message.orderId,
          },
        })
        return
      }
      if (message.event === 'SapOrderConfirmationPdfFailed') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.OrderConfirmationPDFFailed,
          id: `${message.userId}-${NotificationType.OrderConfirmationPDFFailed}`,
          options: {
            reason: message.reason,
          },
        })
        return
      }

      if (message.feedType === FeedType.SmallConstructionDocumentation) {
        if (message.event === 'ConstructionDocumentationCreated') {
          getEventSubscription().next({
            type: EventType.Data,
            uid: message.uid,
            frontendClientId: message.frontendClientId,
            notificationType: NotificationType.SmallConstructionDocumentationPDFCreated,
            id: `${message.userId}-${NotificationType.SmallConstructionDocumentationPDFCreated}`,
            options: {
              fileUrl: message.fileUrl,
            },
          })
        }
        if (message.event === 'ConstructionDocumentationFailed') {
          getEventSubscription().next({
            type: EventType.Data,
            uid: message.uid,
            frontendClientId: message.frontendClientId,
            notificationType: NotificationType.SmallConstructionDocumentationPDFFailed,
            id: `${message.userId}-${NotificationType.SmallConstructionDocumentationPDFFailed}`,
            options: {
              reason: message.reason,
            },
          })
        }
      }
    })

    socket.on('offer-channel', function (message) {
      if (message.event === 'SapOfferPdfCreated') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.SapOfferPdfCreated,
          id: `${message.userId}-${NotificationType.SapOfferPdfCreated}`,
          options: {
            fileUrl: message.fileUrl,
            offerId: message.offerId,
          },
        })
        return
      }
      if (message.event === 'SapOfferPdfFailed') {
        getEventSubscription().next({
          type: EventType.Data,
          uid: message.uid,
          frontendClientId: message.frontendClientId,
          notificationType: NotificationType.SapOfferPdfFailed,
          id: `${message.userId}-${NotificationType.SapOfferPdfFailed}`,
          options: {
            reason: message.reason,
          },
        })
      }
    })
    socket.on('feed-update-notifications', (message) => {
      switch (message.feedType) {
        case FeedType.UserAddress: {
          getCollectionSync('useraddressesv2')?.reSync()
          return
        }
        case FeedType.Cart: {
          trackCustom('resync-cartsv2-websocket-notification')
          getCollectionSync('cartsv2')?.reSync()
          return
        }
        case FeedType.CartTemplate:
          getCollectionSync('carttemplates')?.reSync()
          return
        case FeedType.Offer:
          {
            let eventType: EventType | undefined = undefined
            let notificationType: NotificationType | undefined = undefined
            const offerEvent = message.event as OfferEvent

            if (offerEvent === OfferEvent.Add) {
              eventType = EventType.Snackbar
              notificationType = NotificationType.OfferAdd
            }
            if (offerEvent === OfferEvent.Update) {
              eventType = EventType.Snackbar
              notificationType = NotificationType.OfferUpdate
            }
            if (offerEvent === OfferEvent.Delete) {
              eventType = EventType.Data
              notificationType = NotificationType.OfferDelete
            }

            // Set new event by event and notification types
            if (eventType && notificationType) {
              getCollectionSync('offersv2')?.reSync()
              getEventSubscription().next({
                type: eventType,
                uid: message.uid,
                frontendClientId: message.frontendClientId,
                notificationType: notificationType,
                id: `${message.objectId}-${notificationType}`,
                options: {
                  offerId: message.objectId,
                },
              })
            }
          }
          break
        case FeedType.Order:
          {
            let eventType: EventType | undefined = undefined
            let notificationType: NotificationType | undefined = undefined
            const orderEvent = message.event as OrderEvent

            if (
              (orderEvent === OrderEvent.Update || orderEvent === OrderEvent.Delete) &&
              location.pathname === '/order-details' &&
              message.objectId === location.search.toString().substring(3) // location.search:  id=2000975594
            ) {
              eventType = EventType.Data
              notificationType = NotificationType.OrderUpdate
            }

            // Set new event by event and notification types
            if (eventType && notificationType) {
              getEventSubscription().next({
                type: eventType,
                uid: message.uid,
                frontendClientId: message.frontendClientId,
                notificationType: notificationType,
                id: `${message.objectId}-${notificationType}`,
                options: {
                  orderId: message.objectId,
                },
              })
            }
          }
          break

        case FeedType.OrderMetaData:
          {
            let eventType: EventType | undefined = undefined
            let notificationType: NotificationType | undefined = undefined
            const orderMetaDataEvent = message.event as OrderMetaDataEvent

            if (orderMetaDataEvent === OrderMetaDataEvent.Update) {
              eventType = EventType.Data
              notificationType = NotificationType.OrderUpdate
            }
            if (eventType && notificationType) {
              getEventSubscription().next({
                type: eventType,
                uid: message.uid,
                frontendClientId: message.frontendClientId,
                notificationType: notificationType,
                id: `${message.objectId}-${notificationType}`,
                options: {
                  orderId: message.objectId,
                },
              })
            }
          }
          break
      }
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('order-channel')
      socket.off('offer-channel')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, companyId])
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <SocketContext.Provider value={socketState}>{children}</SocketContext.Provider>
}
